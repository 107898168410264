import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import logo from '../assets/images/logo.png';

const AnimatedIntro = ({ onEnter }) => {
  const [showSlogan, setShowSlogan] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSlogan(true);
    }, 1000); // Show slogan after 1 second

    const logoTimer = setTimeout(() => {
      setShowLogo(true);
    }, 2000); // Show logo after showing slogan

    const finalDelay = setTimeout(() => {
      onEnter(); // Call onEnter to navigate to home
    }, 5000); // Call onEnter after logo is displayed

    return () => {
      clearTimeout(timer);
      clearTimeout(logoTimer);
      clearTimeout(finalDelay);
    };
  }, [onEnter]);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-white flex flex-col items-center">
      <div className="text-center">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{
            fontSize: '5rem',
            background: 'linear-gradient(45deg, #3498db, #8e44ad)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            height: '300px', // Fixed height for consistent spacing
            lineHeight: '400px', // Center vertically within the height
          }}
        >
          Σ ∫ π ∞ μ σ
        </motion.h1>

        {showSlogan && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            style={{
              fontSize: '1.75rem',
              marginTop: '10px',
              color: '#C0C0C0',
              lineHeight: '50px', // Maintain spacing
            }}
          >
            navigating financial uncertainty with precision
          </motion.p>
        )}

        {showLogo && (
          <div className="flex justify-center mt-4"> {/* Center the logo wrapper */}
            <motion.img
              src={logo}
              alt="Company Logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="max-w-md"
              style={{
                objectFit: 'contain',
                width: '100%', // Ensure it takes the full width
                height: 'auto', // Maintain aspect ratio
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimatedIntro;