// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AnimatedIntro from './components/AnimatedIntro';
import Home from './components/Home';

const App = () => {

  const [hasEntered, setHasEntered] = useState(false);

  // Function to handle the user's entry into the website
  const handleEnterWebsite = () => {
    setHasEntered(true);
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="A.A., AA, A.A. Actuarial, AA Actuarial, A.A. Actuarial Consulting, AA Actuarial Consulting, A.A. Actuarial Consulting Nepal, AA Actuarial Consulting Nepal, Actuarial Consulting Nepal, Acturial Consulting Nepal, Actuarial Consulting Kathmandu, Acturial Consulting Kathmandu, Actuary Valuation Nepal, Actuarial Valuation Nepal, Actuary Nepal, Actuarial Nepal, Employee Benefits Valuation Nepal, Employee Benefits Nepal, Employee Benefits Actuarial Valuation Nepal, NAS 19 Valuation Nepal, NAS19 Valuation Nepal, NAS19 Nepal, NAS19" />
        <title>{hasEntered ? 'Home - A.A. Actuarial Consulting' : 'Welcome - A.A. Actuarial Consulting'}</title>
        <meta name="description" content="Providing actuarial consulting services based in Nepal." />
      </Helmet>
      
      {/* Conditional rendering based on the hasEntered state */}
      {!hasEntered ? (
        <AnimatedIntro onEnter={handleEnterWebsite} />
      ) : (
        <Home />
      )}
    </div>
  );
};

export default App;