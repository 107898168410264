// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#003459] text-white py-8 px-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex flex-col">
          <div className="flex space-x-4">
            <a href="#about-us" className="hover:underline">About Us</a>
            <a href="#why-us" className="hover:underline">Why Us</a>
            <a href="#our-services" className="hover:underline">Our Services</a>
            <a href="#work-with-us" className="hover:underline">Work With Us</a>
            <a href="#contact-us" className="hover:underline">Contact Us</a>
          </div>
        </div>
      </div>

      <div className="text-right max-w-6xl mx-auto px-4">
        <p className="text-sm">© {new Date().getFullYear()} A.A. Actuarial Consulting Private Limited. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;