import React, { useState } from 'react';

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contactError, setContactError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSfoIEqgwxEM2IehNapy3Z0qRprg_BHQo1oUtQJxSIOfSWzFQA/formResponse'; 

    // Reset errors
    setEmailError('');
    setContactError('');
    setMessageError('');
    setSubmitError('');

    // Validate email, contact number, and message
    const emailValid = validateEmail(email);
    const contactValid = validateContact(contact);
    const messageValid = message.trim() !== '';

    if (!emailValid) {
      setEmailError('Please enter a valid email address.');
    }
    if (!contactValid) {
      setContactError('Please enter a 10-digit contact number.');
    }
    if (!messageValid) {
      setMessageError('Please enter a message.');
    }

    // If any field is invalid, prevent submission
    if (!emailValid || !contactValid || !messageValid) {
      return;
    }

    setLoading(true);

    fetch(googleFormURL, {
      method: 'POST',
      mode: 'no-cors',
      body: new URLSearchParams(data),
    })
      .then(() => {
        form.reset();
        setEmail('');
        setContact('');
        setMessage('');
        setDialogOpen(true); // Open the dialog on successful submission
      })
      .catch((error) => {
        setSubmitError('There was an error submitting your response. Please try again.');
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateContact = (contact) => {
    const re = /^\d{10}$/;
    return re.test(contact);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <input name="entry.2024349500" type="text" placeholder="Your Name" className="border p-2 w-full" required />
        <input name="entry.2001338332" type="text" placeholder="Company Name" className="border p-2 w-full" required />
        <input name="entry.1475017113" type="text" placeholder="Job Title" className="border p-2 w-full" required />
        <input
          name="entry.968547730" // Business Email entry ID
          type="email"
          placeholder="Business Email"
          className={`border p-2 w-full ${emailError ? 'border-red-500' : ''}`}
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <p className="text-red-500">{emailError}</p>}
        <input
          name="entry.1595742321" // Contact Number entry ID
          type="tel"
          placeholder="Contact Number (10 digits)"
          className={`border p-2 w-full ${contactError ? 'border-red-500' : ''}`}
          required
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
        {contactError && <p className="text-red-500">{contactError}</p>}
        <textarea
          name="entry.252161387" // Requirements entry ID
          placeholder="Requirements"
          className="border p-2 w-full"
          required
          rows="2"
        ></textarea>
        <textarea
          name="entry.1816688943" // Your Message entry ID
          placeholder="Your Message"
          className={`border p-2 w-full ${messageError ? 'border-red-500' : ''}`}
          required
          rows="6"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        {messageError && <p className="text-red-500">{messageError}</p>}
        {submitError && <p className="text-red-500">{submitError}</p>}
        <button type="submit" className="bg-blue-500 text-white p-2" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>

      {/* Dialog Box */}
      {dialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold">Thank You!</h2>
            <p>We have received your request. We will reach out to discuss your needs and our tailored strategies.</p>
            <button onClick={closeDialog} className="mt-4 bg-blue-500 text-white p-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;