import React from 'react';
import Navbar from './Navbar'; 
import ContactForm from './ContactForm';
import Footer from './Footer';

const Home = () => {
  return (
    <div className='bg-[#ffffff]'>
      <Navbar />
      
      <div className="container mx-auto px-8">
        <div className="mb-8 pt-4"></div>

        {/* Hero Section */}
        <section className="bg-[#003459] text-[#ffffff] text-center py-12">
          <h1 className="text-5xl font-bold mb-2">Welcome to A.A. Actuarial Consulting</h1>
          <p className="text-lg italic">navigating financial uncertainty with precision</p>
        </section>

        {/* About Us Section */}
        <section id="about-us" className="p-6 rounded-lg bg-white shadow-lg mb-12 transition duration-300 ease-in-out">
          <h2 className="text-3xl font-bold mb-4 text-[#0b0c0c]">About Us</h2>
          <p className="mb-4 text-justify ">
          A.A. Actuarial Consulting is a specialized actuarial consulting firm based in Nepal, offering tailored, innovative, and effective actuarial solutions to adress clients' needs.
          Notably, all reports will be signed off by the <b className="text-lg">Fellow of the Institute and Faculty of Actuaries, UK based in Nepal</b>, ensuring compliance with local standards and adding an extra layer of credibility to our work.
          </p>
          <p>
          As pioneers in this niche, we are uniquely positioned to address complex challenges with a fresh perspective.
          </p>
        </section>

        {/* Why Us Section */}
        <section id="why-us" className="p-6 rounded-lg bg-[#f9f9f9] shadow-lg mb-12 transition duration-300 ease-in-out">
          <h2 className="text-3xl font-bold mb-4 text-[#003459]">Why Us</h2>
          <ul className="tick-list text-justify">
            <li><b>Expertise and Local Insight:</b> We bring extensive experience in actuarial tasks across various roles within the financial industry. This ensures expert guidance and strict compliance with local regulations and standards.</li>
            <li><b>Tailored Solutions:</b> We provide customized approaches that address the specific needs of your organization, ensuring relevant and effective solutions.</li>
            <li><b>Expert Advisory Services:</b> We offer strategic guidance and insights to help you make informed decisions and enhance your financial strategies.</li>
            <li><b>Commitment to Quality:</b> Our rigorous checks, methodologies, and adherence to professional practice standards ensure the quality of our work as expected by our clients.</li>
            <li><b>Timely and Responsive Support:</b> We ensure prompt and effective assistance to keep your project on track and address any issues promptly.</li>
            <li><b>Client Satisfaction Focus:</b> We are dedicated to achieving high levels of client satisfaction through attentive service and a focus on detail.</li>
          </ul>
        </section>

        {/* Our Services Section */}
        <section id="our-services" className="p-6 rounded-lg bg-white shadow-lg mb-12 transition duration-300 ease-in-out">
          <h2 className="text-3xl font-bold mb-4 text-[#003459]">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="service-item p-4 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <div className="flex items-center mb-2">
                <i className="fas fa-user-friends mr-2 text-[#003459]"></i>
                <h3 className="text-2xl font-semibold">Actuarial Valuation of Employee Benefits</h3>
              </div>
              <ul className="list-disc list-inside ml-5">
                <li>Actuarial Valuation of Employee Benefits in accordance with NAS 19, IAS 19.</li>
                  <ul className="dash-list list-inside ml-5">
                      <li>Post-Employment Defined Benefits</li>
                      <li>Other Long-Term Employee Benefits</li>
                    </ul>
              </ul>
            </div>

            <div className="service-item p-4 border rounded-lg shadow-md bg-[#f9f9f9] hover:shadow-lg transition duration-300">
              <div className="flex items-center mb-2">
                <i className="fas fa-laptop-code mr-2 text-[#003459]"></i>
                <h3 className="text-2xl font-semibold">Insurance Consulting</h3>
              </div>
              <ul className="list-disc list-inside ml-5">
                <li>Services as Consulting Actuary for Life Insurance, General Insurance, and Reinsurance:
                  <ul className="dash-list list-inside ml-5">
                    <li>Product Design and Development</li>
                    <li>Product Pricing</li>
                    <li>Experience Studies</li>
                    <li>Actuarial Valuation of Policy Liability (Liability Adequacy Test)</li>
                    <li>Policyholders' Bonus Review</li>
                    <li>Capital Management (Risk Based Capital (RBC))</li>
                    <li>Risk Management (Own-Risk and Solvency Assessment (ORSA))</li>
                    <li>NFRS 17 Insurance Contracts Implementation</li>
                    <li>Investment Consulting</li>
                    <li>Reinsurance Arrangement Review</li>
                    <li>Regulatory Reporting and Compliance</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="service-item p-4 border rounded-lg shadow-md bg-[#f9f9f9] hover:shadow-lg transition duration-300">
              <div className="flex items-center mb-2">
                <i className="fas fa-chart-line mr-2 text-[#003459]"></i>
                <h3 className="text-2xl font-semibold">Enterprise Risk Management</h3>
              </div>
              <ul className="list-disc list-inside ml-5">
                <li>Risk Identification and Assessment</li>
                <li>Modeling and Forecasting</li>
                <li>Economic Capital Modelling</li>
                <li>Mitigation Strategies</li>
                <li>Reporting and Governance</li>
              </ul>
            </div>

            <div className="service-item p-4 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <div className="flex items-center mb-2">
                <i className="fas fa-chart-line mr-2 text-[#003459]"></i>
                <h3 className="text-2xl font-semibold">Other Services</h3>
              </div>
              <ul className="list-disc list-inside ml-5">
                <li>Data Analytics</li>
                <li>Capacity Development</li>
              </ul>
            </div>
          </div>
          
        </section>

        {/* Work With Us Section */}
        <section id="work-with-us" className="p-6 rounded-lg bg-[#e3f2fd] shadow-lg mb-12 transition duration-300 ease-in-out">
          <div className="flex">
            <div className="w-1/2 pr-4">
              <h2 className="text-3xl font-bold mb-4 text-[#003459]">Work With Us</h2>
              <p className="mb-4 text-lg font-semibold">
                Interested in our consulting solutions?
              </p>
              <p className="mb-4">
                Fill out the form, and we will reach out to discuss your needs and our tailored strategies.
              </p>
            </div>
            <div className="w-1/2">
              <ContactForm />
            </div>
          </div>
        </section>

        {/* Contact Us Section */}
        <section id="contact-us" className="p-6 rounded-lg bg-[#f3f4f6] shadow-lg mb-12 transition duration-300 ease-in-out">
          <h2 className="text-3xl font-bold mb-4 text-[#003459]">Contact Us</h2>
          <div className="flex">
            <div className="w-1/2 pr-4">
              <address className="mb-4">
                <div className="text-[#003459] font-normal">Tripureshwor, Kathmandu Metropolitan City - 11,<br />Kathmandu, Bagmati,<br /> NEPAL</div>
                <br />
                <span>Phone: <a href="tel:+9779802378817" className="text-[#003459] hover:underline"><strong>+9779802378817</strong></a></span><br />
                <span>Email: <a href="mailto:info@aaactuarialconsulting.com" className="text-[#003459] hover:underline">info@aaactuarialconsulting</a></span>
              </address>
            </div>
            <div className="w-1/2">
              <div className="w-full h-64 rounded-lg overflow-hidden shadow-lg">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.761382938877!2d85.31157527546691!3d27.693768976190384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1952442afd71%3A0xec894af3e5c1ebaf!2sA.A.%20Actuarial%20Consulting%20Private%20Limited!5e0!3m2!1sen!2snp!4v1726770668219!5m2!1sen!2snp" 
                  width="100%" 
                  height="100%" 
                  style={{ border: 0 }} 
                  allowFullScreen="" 
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Home;