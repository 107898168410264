import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../assets/images/logo.png'; // Import the logo image
import { FaLinkedin, FaTwitter, FaFacebook, FaWhatsapp, FaViber } from 'react-icons/fa';

const Navbar = () => {
  return (
    <nav className="bg-white shadow">
      <div className="max-w-6xl mx-auto px-4 py-2">
        {/* Social Media Icons */}
        <div className="flex justify-end space-x-4 mb-1">
          <a href="https://www.linkedin.com/company/aaactuarialconsulting" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="text-gray-700 hover:text-blue-600" size={24} />
          </a>
          <a href="https://x.com/aaactuarialnpl" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="text-gray-700 hover:text-blue-400" size={24} />
          </a>
          <a href="https://www.facebook.com/people/AA-Actuarial-Consulting/61566450350040/" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-gray-700 hover:text-blue-500" size={24} />
          </a>
          <a href="https://wa.me/9779802378817" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="text-gray-700 hover:text-blue-500" size={24} />
          </a>
          <a href="viber://chat?number=9779802378817" target="_blank" rel="noopener noreferrer">
            <FaViber className="text-gray-700 hover:text-blue-500" size={24} />
          </a>
        </div>

        <div className="flex justify-between items-center">
          {/* Logo - Clickable to reload home page */}
          <Link to="/">
            <img src={logo} alt="Logo" className="h-28 w-auto mr-2 cursor-pointer" />
          </Link>

          {/* Navigation Links */}
          <div className="navbar-links flex mx-4 space-x-4">
            <a href="#about-us">About Us</a>
            <a href="#why-us">Why Us</a>
            <a href="#our-services">Our Services</a>
            <a href="#work-with-us">Work With Us</a>
            <a href="#contact-us">Contact Us</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;